import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Sweden",
  "subtitle": "May 2014",
  "category": "Europe"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Cinnamon_roll"
        }}>{`Kanelbulle`}</a>{` - Cinnamon rolls originated here`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://sweden.se/culture/food/swedish-meatballs"
        }}>{`Köttbullar`}</a>{` - Swedish meatballs`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.maxburgers.com/"
        }}>{`Max Burgers`}</a>{` - Swedish hamburger chain`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Fat%C3%A1ny%C3%A9ros"
        }}>{`Plankstek`}</a>{` - Hungarian dish popular in Sweden. It’s a steak served on a wood board with duchess potatoes and béarnaise sauce.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Sm%C3%B6rg%C3%A5sbord"
        }}>{`Smörgåsbord`}</a></li>
    </ul>
    <h3 {...{
      "id": "shopping"
    }}>{`Shopping`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VSAvzpUhJZrmDcDm7"
        }}>{`Designtorget`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/irzcnseGRjRGSsjg9"
        }}>{`Granit`}</a>{` - Furnishing shop with interesting stuff.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/H%26M"
        }}>{`H & M`}</a>{` is Swedish!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/srAfAtA8nU3HeJfK6"
        }}>{`Ikea`}</a>{` - Kungens Kurva branch is the largest in Sweden`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/STapCeE23wtfXjR39"
        }}>{`Sandqvist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/SpNpGEbNqAfLvwj26"
        }}>{`Svenskt Tenn`}</a></li>
    </ul>
    <h2 {...{
      "id": "stockholm"
    }}>{`Stockholm`}</h2>
    <ul>
      <li parentName="ul">{`Get around via `}<a parentName="li" {...{
          "href": "https://sl.se/en/in-english"
        }}>{`public transit`}</a></li>
    </ul>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/g297ndTa8u5pQM538"
        }}>{`Carmen Bar`}</a>{` - Where a friend showed me the Plankstek!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/P7UkKDbim2YdubYg6"
        }}>{`StikkiNikki Gelato`}</a>{` - Family owned homemade ice cream`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/abJfHGHNxDPk6bKo7"
        }}>{`Shogun`}</a>{` - Wanted to try Asian food`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Dxw7kSBLaEniefW68"
        }}>{`Vasa Museum`}</a>{` - Highly recommend going when it opens to avoid long lines; very impressive ship!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/EnpzWdQ79UTGLgt17"
        }}>{`Millesgården`}</a>{` - Nice sculpture garden and art museum`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/mkhg7mPcLQdZBDHw9"
        }}>{`Järnpojke`}</a>{` - Smallest public monument in Stockholm is this ion boy sculpture in Gamla stan`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Z2hPHny9LRkWZQh6A"
        }}>{`Prins Eugens Waldemarsudde`}</a>{` - Surrounding area and the museum are both nice`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/8pManm1w9RreNons9"
        }}>{`Fotografiska`}</a>{` - Contemporary photography`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/XMBU9dd9uqPqmCjN9"
        }}>{`Royal Palace`}</a>{` - Guards changed at noon when I was there`}</li>
    </ul>
    <h2 {...{
      "id": "malmö"
    }}>{`Malmö`}</h2>
    <ul>
      <li parentName="ul">{`This was a stop over on my way to Copenhagen (Denmark)`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/JgiKrxJ4pyehagoV9"
        }}>{`Gamla Staden`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/wemfAD1ssQ8CL87XA"
            }}>{`Gustav Adolfs torg`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/2HpV9fJcSKwogduK6"
        }}>{`Stortorget`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/rakQ5AwEsNJLH7eZ6"
            }}>{`Lilla torg`}</a>{` - Known as the little square`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/HPTL65c7SmVABmjJ9"
            }}>{`Malmö Rådhus`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/Yu2jRVZCJxXNhqzq8"
            }}>{`St. Peter’s Church`}</a>{` is nearby`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/g71mdT5qvLZEUtsy9"
        }}>{`Ankarparken`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/9mB2DnwyaPyhej977"
            }}>{`Dania Park`}</a>{` is nearby`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/zuGb5TaF9JmHiAt67"
            }}>{`Stapelbäddsparken`}</a>{` - Skateboarding park nearby`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/QQmH2rtfD8wWi3SQA"
            }}>{`Turning Torso`}</a>{` - Futuristic skyscraper`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/fGQqyh9WAw3VyLeJ8"
        }}>{`Malmö Castle`}</a></li>
    </ul>
    <h2 {...{
      "id": "gothenburg"
    }}>{`Gothenburg`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.stenalinetravel.com/routes/frederikshavn-gothenburg"
        }}>{`Stena Line`}</a>{` - Ferry between Frederikshavn (Denmark) and Gothenburg (Sweden)`}</li>
    </ul>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/izL243E9Ys71im1y8"
        }}>{`Kajutan`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/RMDPwKXuL27Xg5D87"
        }}>{`Feskekôrka`}</a>{` - Indoor fish market`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NBqZThdR6F1kw8cG6"
        }}>{`Maritiman`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Tn23Abv4rkbr2AT79"
        }}>{`Paddan Sightseeing`}</a>{` - Tour through the moats, canals and the harbor area`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      